@import './variables.scss';

    /* Alignment */
    .rightAlign {
        text-align: right;
        margin-top: 17px;
        padding-right: 30px;
    }

    .marginCenter {
        margin: 0 auto;
    }

    /* Spacers */
    .spacer10 {
        height: 10px;
        background: transparent;
    }

    .spacer20 {
        height: 20px;
        background: transparent;
    }

    .spacer30 {
        height: 30px;
        background: transparent;
    }

    .spacer40 {
        height: 40px;
        background: transparent;
    }

    .spacer50 {
        height: 50px;
        background: transparent;
    }

    /* Page Titles */
    .pageTitle {
        color: $SAICBlue;
        font-style: italic;
        font-size: 24px;
        font-family: 'Times New Roman', Times, serif;
        opacity: 0.7;
        margin: 10px 0 0 10px;
    }

    /* Cards */
    .dashboardCard {
        background: $headerContainerBackgroundColor;
    }

    /* Trimming Text */
    .text-overflow-clip {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
       border: none;
    }

    /* links */
    a:link {
        text-decoration: none;
        color: $SAICBlue;
    }

    a:visited {
        text-decoration: none;
        color: $SAICBlue;
    }

    a:hover {
        color: $SAICLiteBlue;
    }


    /* Fonts */
    .h5 {
        font-style: bold;
        font-size: 20px;
        color: $SAICBlue;
    }
   

    /* Loader misc */
    .loaderText {
        color: $SAICBlue;
        font-style: italic;
        font-size: 18px;
        opacity: 0.7;
        margin-bottom:5px;
    }