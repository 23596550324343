@import url("./variables.scss");

/* Bubble Boxes */

.messageBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: #333;
  border: 2px solid #006bb6;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  width:500px;
  font-size: 15px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.messageBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.messageBubble::after {
  border: 2px solid #006bb6;
  border-bottom: 0;
  bottom: -20px;
  left: 50%;
  margin-left: -90px;
}

.validationBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: #fff;
  background: #b54f58;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  max-width: 320px;
  font-size: 15px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.validationBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.validationBubble::after {
  border-top-color: #b54f58;
  border-bottom: 0;
  bottom: -20px;
  left: 50%;
  margin-left: -90px;
}

.searchResultsBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: $white;
  background: $SAICBlue;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 320px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  float: left;
  margin: 0 auto;
}

.searchResultsBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.searchResultsBubble::after {
  // border-top-color: $primary;
  border-bottom: 0;
  bottom: -10px;
  left: 50%;
  margin-left: -20px;
}

/* Floating */
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin: 0 auto;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

// loading
.loadingContainer {
  width: 75%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  padding: 10px;
  z-index: 1;
  border-radius: 25px;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin: 0.19em;
  background: #007db6;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading h2 {
  color: $SAICBlue;
  font-size: 16px;
}

.loading span:nth-of-type(2) {
  background: #016bb5;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #1c91e6;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #52b5fc;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #82cbff;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #82cbff;
  animation-delay: 1s;
}
.loading span:nth-of-type(7) {
  background: #b6c743;
  animation-delay: 1.2s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




  /* Spinner */

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    margin: 0 auto;
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $SAICBlue;
    border-color: $SAICBlue transparent $SAICBlue transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


/* Fade Animation */

.fadePanel {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
