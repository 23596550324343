/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$black: #000000;
$white: #ffffff;
$grey: #f8f7f7;

$primary: #0f183f;
$background: $grey;
$highliteFontColor: #63f2eb;
$backgroundTableColor: #9a9a9a;
$tableHighliteColor: #c6fff8;

//SAIC Color Palate
$SAICBlue: #006bb6;
$SAICLiteBlue: #2d9ae8;
$SAICGreen: #bcd63e;
$SAICGrey: #64696f;
$SAICLiteGrey: #e1e1e1;

/* Header Container */
$headerContainerHeight: 75px;
$headerContainerPaddingTop: 1px;
$headerContainerPaddingLeft: 10px;
$headerContainerBackgroundColor: linear-gradient(to right, #006bb6, #51a9e8);
$headerContainerImgMarginTop: 5px;
$headerContainerImgMarginLeft: 55px;
$headerContainerImgWidth: auto;
$headerContainerImgHeight: 45px;
$headerContainerImgPaddingTop: 5px;
$headerContainerImgFloat: left;

