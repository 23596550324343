@import "./variables.scss";

body {
  background: #fff;
  margin: 0;
}

/* Header Container */
.headerContainer {
  height: $headerContainerHeight;
  padding-top: $headerContainerPaddingTop;
  padding-left: $headerContainerPaddingLeft;
  background: $headerContainerBackgroundColor;
}

.headerContainer img {
  margin-top: 5px;
  padding-top: $headerContainerImgPaddingTop;
  margin-left: -70px;
  height: $headerContainerImgHeight;
  width: $headerContainerImgWidth;
  float: $headerContainerImgFloat;
}

/* Footer */
.footerContainer {
  background: #efefef;
  min-height: 100px;
  height: 200px;
}

.footerContainer img {
  margin: 25px auto;
  height: 30px;
  margin-left: 25px;
}

.footerContainer h6 {
  color: #cecece;
  float: right;
  margin-top: 25px;
  margin-right: 25px;
}

/* Menu */
.menuName {
  float: right;
  text-align: right;
  margin-top: 2px;
  font-size: 19px;
}

/* Content Pages */
.homeContainer {
  margin: 50px auto;
  width: 100%;
  min-height: 65vh;
  height: 100%;
}

.homeContainer a {
  text-decoration: none;
  color: #fff;
}

.leftContainer {
  border-radius: 25px;
  padding: 10px;
  height: 450px;
  margin: 0 auto;
  color: #fff;
}

.rightContainer {
  height: 450px;
  padding: 10px;
  border-radius: 25px;
  margin: 0 auto;
}

.homeContainer h6 {
  color: #fff;
}

/* Create Proposals */
.createProposalsContainer {
  min-height: 75vh;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.createForm {
  height: 100%;
  padding: 40px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 0px;
}

.createForm h6 {
  font-size: 18px;
  color: #909090;
}

/* Search Page */
.searchContainer {
  margin: 5px auto;
  padding-bottom: 20px;
  min-height: 100vh;
}

.searchBox {
  border-radius: 100px;
  box-shadow: 2px 1px 1px #999;
  background-color: #fff;
}

.searchNlpButton {

  margin-top: 5px;
  margin-right: 10px;
}


/* Proposal Response Page */
.pdfKeywordSection ul {
  padding-left: 15px;
}

.pdfKeywordSection > ul {
  max-height: 800px;
  overflow: scroll;
  padding-right: 15px;
}

.keywordSectionHeader {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  color: $SAICBlue;
}

.keywordSectionItem {
  cursor: pointer;
  color: $SAICGrey;
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  transition: color 0.35s ease-in-out;
  display: flex;
}

.keywordSectionItem:hover {
  color: #64696fc4;
}

.pdfKeywordSection h3 {
  color: $SAICBlue;
  margin: 0px;
  margin-bottom: 5px;
}

.keywordTextArea {
  border-radius: 4px;
  border: 1px solid $SAICBlue;
  width: 100%;
}

.textAreaError {
  outline: #e25465;
  outline-style: solid;
  outline-width: 2px;
}

.keywordSectionItem span {
  flex: 1;
  text-decoration: underline;
}

.icon {
  width: 33px;
  height: 33px;
}

.editIcon {
  color: $SAICBlue;
}

.closeIcon {
  color: $black;
}

.deleteIcon {
  color: #e25465;
}

.responseOutlineHeaderContainer {
  display: flex;
  align-items: center;
}

.responseOutlineHeaderContainer h3 {
  flex: 1;
}

/* View Proposals */
.viewProposalsContainer {
  min-height: 75vh;
  height: 100%;
  width: 90%;
  margin: 0 auto;
}

.viewForm {
  height: 90%;
  padding: 25px;
  background-color: #f9f9f9;
  border-radius: 20px;
  margin: 25px auto;
}

.viewForm h6 {
  font-size: 18px;
  color: $SAICBlue;
}

/* Proposal Details */
.proposalDetailsContainer {
  min-height: 75vh;
  height: 100%;
  width: 90%;
  margin: 0 auto;
}

.detailsForm {
  height: 90%;
  padding: 25px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin: 25px auto;
}

.detailsForm .select {
  margin: 0 auto;
  width: 50%;
}

.proposalDetailsContainer h6 {
  color: $SAICBlue;
}

/* Generated Response */

.richTextEditorToolbar {
  padding: 18px;
  padding-bottom: 0px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid $SAICGrey;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.richTextEditorToolbarButton {
  border-radius: 4px;
  color: $SAICGrey;
}

.richTextEditorBtnActive {
  color: $SAICBlue;
}

.hovering-toolbar {
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  max-width: 400px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -1000px;
  left: -10000px;
  opacity: 0;
  background-color: #f3f3f3;
  border-radius: 4px;
  transition: opacity 0.75s;
  margin-top: -6px;
}
